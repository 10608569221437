.container {
    background: rgb(255, 254, 254);
    border-radius: 5px;
    margin-top: 3em;
    margin-bottom: 3em;
    padding: 0.9375rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: fit-content;
    overflow: hidden;
}

#logo {
    position: relative;
    top: 10px;
    left: 15px;
}

label {
    font-weight: 600;
}

h1,
h2,
h3,
h4 {
    font-family: 'VippsHeader', Arial, sans-serif;
    margin-bottom: 1rem;
}

h1 {
    font-weight: 600;
    color: #722ac9;
}

h2,
h3,
h4 {
    font-weight: 500;
    color: #722ac9;
}

.toggle *,
.toggle,
.toggle svg {
    background-color: transparent !important;
    color: #551488 !important;
    margin: 0 0.5em 0 0.5em;
    font-size: 1.2em;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.toggler {
    border-radius: 5px;
    margin-left: 2.5em;
}

.error-false {
    display: none;
}

.error-true {
    background-color: rgb(255, 191, 170);
    border-radius: 5px;
    padding: 0.5em;
    margin: 0.5em;
    color: black;
    font-weight: 700;
}

.grid-container {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    column-gap: normal;
    column-gap: 2px;
    align-items: center;
    justify-items: center;
    grid-template-areas:
        'header header header header'
        'main main';
}

grid_item:first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.code {
    margin-top: 10px;
}

.tooltip {
    display: inline-block;
    position: relative;
    border-bottom: 1px dotted #666;
    text-align: left;
}

.tooltip .tooltiptext {
    min-width: 200px;
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translate(0, -50%);
    padding: 10px 20px;
    color: #444444;
    background-color: #eeeeee;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltiptext i {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
}

.tooltip .tooltiptext i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
    background-color: #eeeeee;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

input[type='text'] {
    padding: 5px;
    margin: 4px !important;
    height: 30px;
    position: relative;
    left: 0;
    outline: none;
    /* border: 1px solid #cdcdcd; */
    border-color: rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 16px;
    margin: 1px;
}

input[type='select'] {
    padding: 5px;
    margin: 4px !important;
    height: 30px;
    position: relative;
    left: 0;
    outline: none;
    border: 1px solid #cdcdcd;
    border-color: rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 16px;
    margin: 1px;
}

input[type='number'] {
    padding: 5px;
    margin: 4px !important;
    height: 30px;
    position: relative;
    left: 0;
    outline: none;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 16px;
    margin: 1px;
}

input[type='select'] {
    padding: 0;
    height: 30px;
    position: relative;
    left: 0;
    outline: none;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.15);
    background-color: white;
    font-size: 16px;
    margin: 1px;
}
input:focus {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
