.cmd {
    width: 16.67%;
    max-width: 100px;
}
.cmd:disabled {
    background-color: lightgrey;
}
span.cmd-container {
    display: block;
    overflow: hidden;
}
#schedule {
    height: 6em;
    display: inline-block;
}
.size-0-5 {
    background-color: white !important;
    border-radius: 0 !important;
    border: 2px solid #551488 !important;
    color: #551488 !important;
    font-size: 1.2em;
    padding: .5em;
    margin: 0;
}