.api-policy-true,
.pipeline-true {
    color: rgb(255, 91, 36);
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.api-policy-false,
.pipeline-false {
    color: #551488;
    background-color: #f6f6f9;
}
.nav-link,
.nav-link:hover {
    color: inherit;
}
