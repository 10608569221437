html,
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-color: #fff4ec;
}

.wallpaper {
    position: absolute;
    top: 50px; /* Header Height */
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f9;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.top {
    position: relative;
    display: ruby;
    margin-top: 40px;
    margin-bottom: 20px;
}
