.wallpaper {
    overflow-y: scroll;
}

.center-border {
    padding: 0.5em;
    margin-top: 3em;
    margin-bottom: 2em;
    margin-left: 15em;
    margin-right: 15em;
    border: #212529;
    border-width: 1px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.pointer:hover {
    cursor: pointer;
}

.toggler:hover {
    background-color: #ff5b24;
}

.output {
    margin-top: 0.5em;
}

.spacing {
    margin-top: 1.5em;
}
