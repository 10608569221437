.html,
body {
    background-color: #ffffff;
}

.body {
    padding: 0.2em 0 0.2em 0;
    background-color: #f6f6f9;
}

.App {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.code {
    min-height: 600px;
    width: 1200px;
    border-radius: 6px;
    border: 1px solid lightgrey;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    background-color: var(--color-accent-muted);
    white-space: pre;
    word-wrap: break-word;
    padding: 1em;
}

.flow {
    background-color: #ffffff;
}

.flow-item {
    margin: 1em;
    padding: 1em;
}

.push {
    background-color: rgb(255, 91, 36);
    color: white !important;
    font-weight: 500;
}
