.env {
    margin: 1em 0 1em 0;
}

.wide {
    min-width: 400px;
}

.hidden {
    display: none;
}
