.input-medium {
    max-width: 100px;
}

.explainer {
    padding: 1em;
}

.size-1-false,
.size-2-false,
.size-3-false,
.size-4-false {
    background-color: white !important;
    border-radius: 0 !important;
    border: 2px solid #551488 !important;
    color: #551488 !important;
    font-size: 1.2em;
    padding: 1em;
}

.size-1-true,
.size-2-true,
.size-3-true,
.size-4-true {
    background-color: white;
    border-radius: 0 !important;
    border: 2px solid rgb(255, 91, 36) !important;
    border: 2px solid rgb(255, 91, 36);
    color: rgb(255, 91, 36) !important;
    font-size: 1.2em;
    padding: 1em;
}
