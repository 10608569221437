ol {
    margin-left: 2em;
}

.left-align {
    text-align: left;
}

span:hover {
    cursor: pointer;
}

.collapse-tip {
    margin-left: 2em;
}
