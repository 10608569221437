.wallpaper {
    overflow-y: scroll;
}

tbody tr {
    cursor: pointer;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: #ff5b24;
    color: white;
}

Input {
    margin: 1em 0em;
}

thead tr th {
    font-size: 1.2em;
}

.prev-next-buttons:hover {
    background-color: #ff5b24;
    color: white;
}

.page-numbers:hover {
    background-color: #ff5b24;
    color: white;
}

.prev-next-buttons,
.disabled {
    color: black;
    border-color: lightgray;
}

.page-numbers {
    color: black;
    border-color: lightgray;
}

.page-item.active .page-link {
    background-color: #ff5b24;
    border-color: lightgray;
    color: white;
}
