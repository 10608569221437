header h1 {
    font-size: 2.5em;
}

.start {
  padding: 3em;
  position: absolute;
  top: 50px; /* Header Height */
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  background-color: #f6f6f9;
  align-items: center;
  text-align: center;
  min-height: 250vh;
}

.start-icon {
    display: inline;
    font-size: 5em;
}

.card {
    position: relative;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);
    transition: 0.3s;
    border-radius: 15px !important;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    min-width: 300px;
    max-width: 300px;
    min-height: 400px;
    max-height: 600px;
    justify-content: center;
}

.card-header {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    align-items: center;
    padding: 0.75rem 1.25rem;
    width: 100% !important;
    margin: 0px !important;
    font-weight: 600 !important;
    font-size: 14pt;
    justify-content: center !important;
    display: flex;
    min-height: 110px;
    border: none;
    color: white !important;
    background-color: #ff5b24 !important;
    padding-bottom: 5px;
    align-content: center;
    background: rgba(0, 128, 0, 0.3);
    font-family: 'VippsHeader', Arial, sans-serif;
}

.card-body {
    text-align: left !important;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
